import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import IPInfo from "./IPInfo";

const useStyles = makeStyles({
    Title: {
        flex: 1
    },
    IPAddress: {
        fontWeight: 'bold'
    }
});

function App() {
    const classes = useStyles();
    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.Title}>
                        fusscat.com
                    </Typography>
                    <Typography className={classes.IPAddress}>
                        <IPInfo/>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default App;
