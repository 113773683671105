import React from 'react';
import fetch from "node-fetch";

class IPInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ipAddress: '*'
        };
    }

    componentDidMount() {
        fetch('https://homeapi.fusscat.com/netinfo')
            .then(res => res.json())
            .then(json => this.setState({ipAddress: json.ipAddress}));
    }

    render() {
        return (
            <span>{this.state.ipAddress}</span>
        );
    }
}

export default IPInfo;
